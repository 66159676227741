import React, { useEffect, useState } from 'react'
import logo from "../../imgcomponents/logo-text.png";
import "./navbar.css";
import WidgetsIcon from '@mui/icons-material/Widgets';
export const NAVBAR = () => {

  return (
    <>
    <div cslassName="navbar-menu">   
      <div className="menu-items">
          <div className="logo-company">
              <img src={logo} alt=""></img>
          </div>
          <ul className='menu-flotante'>
            <a href="/catalogo"><WidgetsIcon/></a>
          </ul>
          <ul className='menu-fijo'>
              <li><a href="/">Inicio</a></li>
              <li><a href="#nosotros">Nosotros</a></li>
              <li><a href="/catalogo">Productos</a></li>
              <li><a href="#contacto">Contacto</a></li>
          </ul>
      </div>
    </div>
  </> 
  )
}
