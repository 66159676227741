import logo from './logo.svg';
import './App.css';
import { Home } from './page/Home';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { CATALOGO } from './catalogo/catalog';

function App() {
  return(
    <>
    {/* <Home></Home> */}
        <Router>
                <Switch> 
                   <Route path={"/"} exact component={Home}></Route>
                  <Route path={"/catalogo"} exact component={CATALOGO}></Route>
                   <Redirect to="/"></Redirect>
                </Switch>
            </Router>
    </>
    
  )
}

export default App;
