export const data = [
  {
    id: 111,
    name: "aretes",
    productos: [
      { 
        id: 1001,
        name: "Arete 1",
        categoria: "aretes",
        subcategoria: "basicos",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/aretes/basicos/5120.JPEG" 
      },
      { 
        id: 1002,
        name: "Arete 2",
        categoria: "aretes",
        subcategoria: "basicos",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/aretes/basicos/5122.JPEG"
      },
      { 
        id: 1003,
        name: "Arete 3",
        categoria: "aretes",
        subcategoria: "basicos",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/aretes/basicos/5190.JPEG"
      },
      { 
        id: 1004,
        name: "Arete 4",
        categoria: "aretes",
        subcategoria: "basicos",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/aretes/basicos/5196.JPEG"
      },
      { 
        id: 1005,
        name: "Arete 5",
        categoria: "aretes",
        subcategoria: "basicos",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/aretes/basicos/5242.JPEG"
      },
      { 
        id: 1006,
        name: "Arete 6",
        categoria: "aretes",
        subcategoria: "basicos",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/aretes/basicos/5377.JPEG"
      },
      { 
        id: 1007,
        name: "Arete 7",
        categoria: "aretes",
        subcategoria: "temporada",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/aretes/temporada/temp1.JPEG"
      },
      { 
        id: 1008,
        name: "Arete 8",
        categoria: "aretes",
        subcategoria: "temporada",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/aretes/temporada/temp2.JPEG"
      },
      { 
        id: 1009,
        name: "Arete 9",
        categoria: "aretes",
        subcategoria: "temporada",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/aretes/temporada/temp3.JPEG"
      },
      { 
        id: 1010,
        name: "Arete 10",
        categoria: "aretes",
        subcategoria: "temporada",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/aretes/temporada/temp4.JPEG"
      },
      { 
        id: 1011,
        name: "Arete 11",
        categoria: "aretes",
        subcategoria: "temporada",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/aretes/temporada/temp5.JPEG"
      },
      { 
        id: 1012,
        name: "Arete 12",
        categoria: "aretes",
        subcategoria: "nuevos",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/aretes/nuevos/nuevo1.jpg"
      },
      { 
        id: 1013,
        name: "Arete 13",
        categoria: "aretes",
        subcategoria: "nuevos",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/aretes/nuevos/nuevo2.jpg"
      },
      { 
        id: 1014,
        name: "Arete 14",
        categoria: "aretes",
        subcategoria: "nuevos",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/aretes/nuevos/nuevo3.jpg"
      },
      { 
        id: 1015,
        name: "Arete 15",
        categoria: "aretes",
        subcategoria: "nuevos",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/aretes/nuevos/nuevo4.jpg"
      },
      { 
        id: 1016,
        name: "Arete 16",
        categoria: "aretes",
        subcategoria: "nuevos",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/aretes/nuevos/nuevo5.jpg"
      },
      { 
        id: 1017,
        name: "Arete 17",
        categoria: "aretes",
        subcategoria: "nuevos",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/aretes/nuevos/nuevo6.jpg"
      },
    ]
  },
  {
    id: 222,
    name: "collares",
    productos: [
      { 
        id: 2001,
        name: "Collar 1",
        categoria: "collares",
        subcategoria: "",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/collares/collar1.jpg"
      },
      { 
        id: 2002,
        name: "Collar 2",
        categoria: "collares",
        subcategoria: "",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/collares/collar2.jpg"
      },
      { 
        id: 2003,
        name: "Collar 3",
        categoria: "collares",
        subcategoria: "",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/collares/collar3.jpg"
      },
      { 
        id: 2004,
        name: "Collar 4",
        categoria: "collares",
        subcategoria: "",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/collares/collar4.jpg"
      },
      { 
        id: 2005,
        name: "Collar 5",
        categoria: "collares",
        subcategoria: "",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/collares/collar5.jpg"
      },
      { 
        id: 2006,
        name: "Collar 6",
        categoria: "collares",
        subcategoria: "",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/collares/collar6.jpg"
      },
    ]
  },
  {
    id: 444,
    name: "retratos",
    productos: [
      {
        id: 4001,
        name: "Retrato 1",
        subcategoria:"",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/retratos/retrato1.JPG"
      },
      {
        id: 4002,
        name: "Retrato 2",
        subcategoria:"",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/retratos/retrato2.JPG"
      },
      {
        id: 4003,
        name: "Retrato 3",
        subcategoria: "",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/retratos/retrato3.jpg"
      },
      {
        id: 4004,
        name: "Retrato 4",
        subcategoria: "",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/retratos/retrato4.jpg"
      }
    ]
  },
  {
    id: 333,
    name: "llaveros",
    productos: [
      { 
        id: 3001,
        name: "llaveros",
        categoria: "llaveros",
        subcategoria: "",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/llaveros/llav1.JPG"
      },
      { 
        id: 3002,
        name: "llaveros",
        categoria: "llaveros",
        subcategoria: "",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/llaveros/llav2.JPG"
      },
      { 
        id: 3003,
        name: "llaveros",
        categoria: "llaveros",
        subcategoria: "",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/llaveros/llav3.JPEG"
      },
      { 
        id: 3004,
        name: "llaveros",
        categoria: "llaveros",
        subcategoria: "",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/llaveros/llav4.JPEG"
      },
      { 
        id: 3005,
        name: "llaveros",
        categoria: "llaveros",
        subcategoria: "",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/llaveros/llav5.JPG"
      },
      { 
        id: 3006,
        name: "llaveros",
        categoria: "llaveros",
        subcategoria: "",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/llaveros/llav6.jpg"
      },
      { 
        id: 3007,
        name: "llaveros",
        categoria: "llaveros",
        subcategoria: "",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/llaveros/llav7.jpg"
      },
    ]
  },
  {
    id: 555,
    name: "adornos",
    productos: [
      {
        id: 5001,
        name: "Adorno 1",
        categoria: "adornos",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/adornos/adorno1.JPG"
      },
      {
        id: 5002,
        name: "Adorno 2",
        categoria: "adornos",
        img: "https://paper-town-catalogo.s3.amazonaws.com/catalogo/adornos/adorno2.jpg",
      }
    ]
  }
]