import React, { useEffect, useState } from 'react'
import "../page/home.css";
import "./catalogo.css";
import { data } from "../catalogo/catalog_data";
import logo from "../imgcomponents/logo-text.png";

import ico1 from "../imgcomponents/iconos/aretes.png";
import ico2 from "../imgcomponents/iconos/collar.png";
import ico3 from "../imgcomponents/iconos/pulsera.png";
import ico4 from "../imgcomponents/iconos/llavero.png";
import ico5 from "../imgcomponents/iconos/retratos.png";
import ico6 from "../imgcomponents/iconos/adornos.png";
import ico7 from "../imgcomponents/iconos/arte.png";
import ico8 from "../imgcomponents/iconos/papeleria.png";
import loading from "../imgcomponents/cargando.gif";
import HomeIcon from '@mui/icons-material/Home';
export const CATALOGO = () => {

    const[search, setsearch]=useState([]);
    const[textsearch, settextsearch]=useState("");
    const[text, settext]=useState("");
    const[band, setband]=useState(false);
    const[load,setload]=useState(false);   
    const [quizaQuisoDecir, setQuizaQuisoDecir] = useState('');
    const[filtrosespeciales, setfiltrosespeciales]=useState(false);
    const[selec1, seselect1]=useState("");
    const[inicio, setinicio]=useState(false);
   
    useEffect(() => {
        if(localStorage.getItem("search")==="vacio"){
            setinicio(true);
        }else{
            handlefiltrolateral(localStorage.getItem("search"));
            localStorage.setItem("search", "vacio");
            setinicio(false);
        }
    }, [])
    


    function handlefiltrolateral(b){
        setband(true);
        settextsearch(b);
        setinicio(false);
        setfiltrosespeciales(false);
        setQuizaQuisoDecir('');
        setload(true);
        setTimeout(() => {
            setload(false);
        }, 2000);

        const dat = data.filter(item=>item.name===b);
        settextsearch(b);
        setsearch(dat[0]?.productos);
    }
    function handlefiltrolateralesp(b){
        setband(true);
        setinicio(false);
        settextsearch("aretes");
        setQuizaQuisoDecir('');
        setfiltrosespeciales(true);
        setload(true);
        setTimeout(() => {
            setload(false);
        }, 2000);
        const dat = data.filter(item=>item.name==="aretes");
        const dat2 = dat[0].productos.filter(item=>item.subcategoria===b);
        setsearch(dat2);
    }
    //funcion para buscar 
    function handletextsearch(){

        setinicio(false);
        setQuizaQuisoDecir("");
        setfiltrosespeciales(false);
        setload(true);
        setTimeout(() => {
            setload(false);
        }, 2000);
        settextsearch(text);
        setband(true);


        if(text==="" || text===" "|| text===null){
            setsearch([]);
        }else{
            const resultados = data.filter(item => item.name.toLowerCase().includes(text.toLowerCase()));
            const resultadoexacto = data.filter(item=>item.name === text);
            setsearch(resultados[0]?.productos);
             if (resultadoexacto.length === 0) {  
                const sugerencia = data.find(item =>{
                    const itemName = item.name.toLowerCase();
                    return itemName.startsWith(text.toLowerCase()) || itemName.includes(text.toLowerCase());
                 }); 
                 if (sugerencia) {
                   setQuizaQuisoDecir(sugerencia.name);              
                 } else {
                   setQuizaQuisoDecir('');
                 }
            } else {
                 setQuizaQuisoDecir('');
            }
        }
    }
    function handlesug(){
        setQuizaQuisoDecir('');
        handlefiltrolateral(quizaQuisoDecir);
    }

    return (
    <>    
        <div className="navbar-menu">   
                <div className="menu-items">
                    <div className="logo-company">
                        <img src={logo} alt=""></img>
                    </div>
                    <ul>
                        <li><a href="/"><HomeIcon></HomeIcon></a></li>
                    </ul>
                </div>
            </div>       
        <div className='catalogo-container'> 
        
        <div className="slid1">
            <div className="slid1search">
            <select className='select-product'>
                <option value="all">Categorias</option>
            </select>
            <input 
                className='input-seacrh-prod' 
                placeholder='Buscar'
                onChange={(e)=>settext(e.target.value)}
            >
            </input>
            <button 
                className="search-button"
                onClick={()=>handletextsearch()}
            >
            </button>
            </div>
            
        </div>
        <div className="catalogo-seccions">
            <div className="categorias">
                <h2 className="text-cat">
                    Categoria
                </h2>
                <ul className='ul-cat'>
                    <li className='list-item' onClick={()=>handlefiltrolateralesp("basicos")}>
                        <img src={ico1} alt='' className="icon-image"></img>
                        <p>Aretes</p>
                    </li>
                    <li className='list-item' onClick={()=>handlefiltrolateral("collares")}>
                        <img src={ico2} alt='' className='icon-image'></img>
                        <p>Collares</p>
                    </li>
                    <li className='list-item' onClick={()=>handlefiltrolateral("pulseras")}>
                        <img src={ico3} alt='' className='icon-image'></img>
                        <p>Pulseras</p>
                    </li>
                    <li className='list-item' onClick={()=>handlefiltrolateral("llaveros")}>
                        <img src={ico4} alt='' className='icon-image'></img>
                        <p>Llaveros</p>
                    </li>
                    <li className='list-item' onClick={()=>handlefiltrolateral("retratos")}>
                        <img src={ico5} alt='' className='icon-image'></img>
                        <p>Porta Retratos</p>
                    </li>
                    <li className='list-item' onClick={()=>handlefiltrolateral("adornos")}>
                        <img src={ico6} alt='' className='icon-image'></img>
                        <p>Adornos</p>
                    </li>
                    <li className='list-item' onClick={()=>handlefiltrolateral("arte")}>
                        <img src={ico7} alt='' className='icon-image'></img>
                        <p>Arte</p>
                    </li>
                    <li className='list-item' onClick={()=>handlefiltrolateral("herramientas")}>
                        <img src={ico8} alt='' className='icon-image'></img>
                        <p>Herramientas</p>
                    </li> 
                    {/*
                    <li>Imanes</li>
                    <li>Recuerdos</li>*/}
                   
                </ul>
            </div>
            <div className="busqueda">
                {
                    load===true?
                    <div className='cargando'>
                        <img src={loading} alt=''></img>
                    </div>
                    :
                    <>
                     <div className="result-search">
                        {
                            band===true?<p>{search?.length} resultados para "{textsearch}"</p>:<></>
                        }  
                    </div> 
                {quizaQuisoDecir && (
                    <div className='result-search2'>
                    <p>Quiza quiso decir: <spam className="quiz-search" onClick={()=>handlesug()}>{quizaQuisoDecir}</spam></p>
                    </div>
                )}
                            
                    <div className='subcategorias' style={filtrosespeciales===false?{"display":"none"}:{"display":""}}>

                        <div className='subcat123'>
                        <label className='label-aretes'>Filtrar: </label>
                        <select 
                            className='filtro-aretes' 
                            onChange={(event) =>{
                                handlefiltrolateralesp(event.target.value)
                                seselect1(event.target.value);
                              }
                            }
                            value={selec1}
                        >
                            <option value="basicos">Básicos</option>
                            <option value="temporada">Temporada</option>
                            <option value="nuevos">Nuevos</option>
                        </select>
                        </div>
                    </div>

                    {
                        inicio===true?
                        <div className="promo">
                            <div className='prommsecciones'>
                                {/* <div className='prom1'>
                                    prueba1
                                </div>
                                <div className='prom2'>
                                    prueba2
                                </div>
                                <div className="prom3">
                                    prueba3
                                </div> */}
                             
                            </div>  
                        </div>
                        :

                        <>
                        {
                        search?.length===undefined||search?.length===0?[]:
                        Array(Math.ceil(search.length / 4)).fill().map((_, groupIndex) => (
                            <div className="carts-search">
                              {
                                // Segundo mapa para dibujar las cartas dentro de cada div
                                search.slice(groupIndex * 4, (groupIndex * 4) + 4).map((item, index) => (
                                  <div className="carta-prod" style={{ gridArea: `carta${index + 1}` }} key={index}>
                                    <img className="img-format" src={item?.img} alt="productos" />
                                    <div className="descripcion-carta-vid">
                                      <p className="title-carta-vid">{item?.name}</p> 
                                    </div>
                                  </div>
                                ))
                              }
                            </div>
                          ))
                            /*search?.map((item, index)=>{
                                const areaIndex = index < 4 ? index + 1 : (index % 4) + 1;
                                console.log(areaIndex);
                                return (
                                    <>
                                    <div className='carts-search'>
                                    <div className="carta-prod" style={{ gridArea: `carta${areaIndex}` }} key={index}>
                                        <img className="img-format" src={item.img} alt="productos"></img>
                                        <div className="descripcion-carta-vid">
                                            <p className="title-carta-vid">{item.name}</p> 
                                        </div>
                                    </div>
                                    </div>
                                    </>
                                )
                            }
                          )  */
                        }
                    </>
                  }
                   
                </>
            }        
            </div>
        </div>
      </div>    
    </>
    
  )
}
