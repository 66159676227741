import React from 'react';
import './slider.css';

export const SLIDER = () => {
  return (
    <div className="slider-navbar">
    <div className="capa-slider-navbar">
        <div className="title-slider">
           <h1 className="text-slider">
              PAPER TOWN
            </h1>
        </div>    
    </div> 
</div>
  )
}
