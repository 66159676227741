import "./home.css";
import logo2 from "../imgcomponents/logo.png";
import { useRef, useState } from "react";

import carta1 from "../imgcomponents/carta1.jpg";
import carta2 from "../imgcomponents/carta2.jpg";
import carta3 from "../imgcomponents/carta3.jpg";
import carta4 from "../imgcomponents/carta4.JPG";
import carta5 from "../imgcomponents/carta5.jpg";
import carta6 from "../imgcomponents/carta6.jpg";
import carta7 from "../imgcomponents/carta7.jpg";
import carta8 from "../imgcomponents/carta8.jpg";
import axios from "axios";
import { FOOTERPT } from "./footer/footer";
import { NAVBAR } from "./navbar/navbar";
import { SLIDER } from "./slider/slider";
export const Home = () => {

    localStorage.setItem("search", "vacio");
    const [usuario, setusuario]=useState(null);
    const [correo, setcorreo]=useState(null);
    const [mensaje, setmensaje]=useState("");


    function enviar_mensaje(){
        var data = {
            "name": usuario,
            "email": correo,
            "Message": mensaje 
        }
        var url = `https://g90wdmq3j8.execute-api.us-east-1.amazonaws.com/prod/sns-papertown`;
        var axiosConfig = {
          headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
        }
        
        axios
        .post(url, data,axiosConfig)
        .then((response) => {
          console.log(response);
         })
        .catch((err) => {
          console.log("error post", err);
        });
    }
    function handleprod(b){
      localStorage.setItem("search", b);
      window.location = "/catalogo";
    }

   
    


  return (
    <div className="container-home">
       <NAVBAR></NAVBAR>
       <SLIDER></SLIDER>
        <div className="body-container-home">

            <div className="container-carts">
                <div className="carts-1">
                 <div className="carts-capa">
                    <p className="p-carts">Collares</p>
                 </div>
                </div>
                <div className="carts-2">
                <div className="carts-capa">
                    <p className="p-carts">Aretes</p>
                 </div>
                </div>
                <div className="carts-3">
                <div className="carts-capa">
                    <p className="p-carts">Manualidades</p>
                 </div>
                </div>
                <div className="carts-4">
                <div className="carts-capa">
                    <p className="p-carts">Arte</p>
                </div>
                 </div>
            </div>
        </div>
    <div className="body-abstract" id="nosotros">
    <div className="body-container-info">
           
            <div className="body-info-frase">
                <p className="frase-p">Papel en movimiento <br/>Filigrama de papel </p>
            </div>
            <div className="body-info-details">
                <div className="body-info-details-1">
                    <img src={logo2}></img>
                </div>
                <div className="body-info-details-2">
                   Ollin Amatl producto artesanal elaborado 100% a mano a base de papel de alta calidad y de colores variados, 
                   utilizando la técnica de filigranam recubierto con un porcelanizador para darle fuerza y hacerlo resistente
                    al agua, no contiene pintura y se engarza utilizando bisuteria fina en algunos productos.
                </div>
           
            </div>
        </div>
    </div>
    <div className="body-section2">
        <div className="title-productos">
            <p>Nuestros productos</p>
        </div>
        <div className="container-productos">
            <div className="cartas-product">
              <div className="produc1" onClick={()=>handleprod("aretes")}>
                <img src={"https://paper-town-catalogo.s3.amazonaws.com/catalogo/dashboard/carta1.jpg"} alt=""></img>
                <div className="prod-capa"></div>
                <p className="p-prod">Aretes</p>
              </div>
              <div className="produc2" onClick={()=>handleprod("collares")}>
                <img src={"https://paper-town-catalogo.s3.amazonaws.com/catalogo/dashboard/carta2.jpg"} alt=""></img>
                <p className="p-prod">Collares</p>
                <div className="prod-capa" ></div>
              </div>
              <div className="produc3" onClick={()=>handleprod("pulseras")}>
                <img src={"https://paper-town-catalogo.s3.amazonaws.com/catalogo/dashboard/carta3.jpg"} alt=""></img>
                <p className="p-prod">Pulseras</p>
                <div className="prod-capa"></div>
              </div>
              <div className="produc4" onClick={()=>handleprod("llaveros")}>
                <img src={"https://paper-town-catalogo.s3.amazonaws.com/catalogo/dashboard/carta4.JPG"} alt=""></img>
                <p className="p-prod">Llaveros</p>
                <div className="prod-capa"></div>
              </div>
              <div className="produc5" onClick={()=>handleprod("retratos")}>
                <img src={"https://paper-town-catalogo.s3.amazonaws.com/catalogo/dashboard/carta5.jpg"} alt=""></img>
                <p className="p-prod">Retratos</p>
                <div className="prod-capa"></div>
              </div>
              <div className="produc6" onClick={()=>handleprod("adornos")}>
                <img src={"https://paper-town-catalogo.s3.amazonaws.com/catalogo/dashboard/carta6.jpg"} alt=""></img>
                <p className="p-prod">Adornos</p>
                <div className="prod-capa"></div>
              </div>
              <div className="produc7" onClick={()=>handleprod("arte")}>
                <img src={"https://paper-town-catalogo.s3.amazonaws.com/catalogo/dashboard/carta7.jpg"} alt=""></img>
                <p className="p-prod">Arte</p>
                <div className="prod-capa"></div>
              </div>
              <div className="produc8" onClick={()=>handleprod("herramientas")}>
                <img src={"https://paper-town-catalogo.s3.amazonaws.com/catalogo/dashboard/carta8.jpg"} alt=""></img>
                <p className="p-prod">Herramientas</p>
                <div className="prod-capa"></div>
              </div>
            </div>
        </div>
    </div>

        {/* Direccion */}
        <div className="contact" id="contacto">
         <div className="formulario">
           
                <h1>Contacto</h1>
                <div className="input-form">
                    <input
                        placeholder="Nombre" onChange={(event)=>setusuario(event.target.value)} value={usuario}
                    ></input>
                </div>
                <div className="input-form">
                    <input
                        placeholder="Email" onChange={(e)=>setcorreo(e.target.value)} value={correo}
                    ></input>
                </div>
                <div className="input-form">
                    <textarea
                        placeholder="Mensaje" onChange={(e)=>setmensaje(e.target.value)} value={mensaje}
                    ></textarea>
                </div>
                <div className="form-sent">
                    <button onClick={()=>enviar_mensaje()}>ENVIAR</button>
                </div>
         </div>
         <div className="direccion-maps">
           <div className="info-direccion-empresa">
            <p className="inf-dir">
                <b>Venta en linea:</b> Orizaba,  Veracruz, Mexico.
            </p>
           </div>
           <div className="mapa-google">
           <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19469.85905240488!2d-97.10451038013994!3d18.854541318263706!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85c502a4c0439f05%3A0xd83faa836b275780!2sOrizaba%2C%20Ver.!5e0!3m2!1ses-419!2smx!4v1688744774610!5m2!1ses-419!2smx" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
           </div>
         </div>
        </div>
        <FOOTERPT></FOOTERPT>

    </div>
  )

}
