import React from 'react'
import logo2 from "../../imgcomponents/logo.png";
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import "./footer.css";

export const FOOTERPT = () => {
 //enviar whatsapp
 const phoneNumber = '2721399524'; // Reemplaza con tu número de WhatsApp
 const handleWhatsAppClick = () => {
   const url = `https://wa.me/${phoneNumber}`;
   window.open(url, '_blank');
 };



return (
    <div className="footer-page">
        <div className="footer-1">
        <img src={logo2} alt=""></img>
        <p className="">PAPER TOWN</p>
        </div>
        <div className="footer-2">
        <p className="title-foot">Contacto</p>
        <p className="text-foot">Orizaba, Veracruz, México</p>
        <p className="text-foot">272 139 9524</p>
        <p className="text-foot">elsacastilloe4@gmail.com</p>
        
        </div>
        <div className="footer-3">
        <p className="title-foot">Nuestras Redes</p>
        <div className="facebook-red">
        <a href="https://www.facebook.com/GuerapucheElsa" target="_blank" rel="noopener noreferrer" className="redes-sociales">
            <FacebookIcon/><span className="redes-sociales-sp">PAPER TOWN</span>
        </a>
        </div>
        <div className="whatsapp-red">
        <a className="redes-sociales" onClick={()=>handleWhatsAppClick()}>
            <WhatsAppIcon/><span className="redes-sociales-sp">PAPER TOWN</span>
        </a>
        </div>
    </div>      
    </div>      
  )
}
